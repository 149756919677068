import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Masonry from 'react-masonry-css'
import * as sections from "../../components/sections"
import * as styles from "../../components/illustrations.css"


import {
  Container,
  Section,
  Box,
  Subhead
} from "../../components/ui"

import ImageCard from "../../components/image-card"
import CustomLightBox from "../../components/light-box"

const Fallback = (props) => {
  console.warn(`No component found for: ${props.blocktype}`)
  return false
}

export default function Illustrations(props) {
  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const { contentfulIllustrationsPage } = props.data
  const { allContentfulIllustrationsSeries } = props.data
  const { contentfulIllustrationsSingleImages } = props.data

  const breakpointColumnsObj = {
    default: 3,
    900: 1,
  };

  return (
    <Layout {...contentfulIllustrationsPage}>

      <Section>
        <Container>
          <Subhead as="h2">Series</Subhead>
          <Masonry
            className={styles.masonryGrid}
            columnClassName={styles.masonryGridCol}
            breakpointCols={breakpointColumnsObj}
          >
            {allContentfulIllustrationsSeries.nodes.map((serie, index) => {
              return <Box className={styles.imgContainer} key={'serie'+index}>
              {serie.cover && (
                  <ImageCard
                    alt={serie.cover.alt}
                    image={getImage(serie.cover.gatsbyImageData)}
                    client={serie.client}
                    title={serie.title.title}
                    url={serie.url ? `./${serie.url}` : null}
                    showTitle={true}
                    onClick={(e) => {
                      if (!serie.url) {
                        return;
                      }
                      e.preventDefault();
                      navigate(`./${serie.url}`);
                    }}
                  >
                  </ImageCard>
              )}
            </Box>
            })}
          </Masonry>
          <Subhead as="h2">Single images</Subhead>
          <Masonry
            className={styles.masonryGrid}
            columnClassName={styles.masonryGridCol}
            breakpointCols={breakpointColumnsObj}
          >
            {contentfulIllustrationsSingleImages.images.map((image, index) => {
              return <Box className={styles.imgContainer} key={'single'+index}>
                {image && (
                  <ImageCard
                    alt={image.alt}
                    client={image.description}
                    image={getImage(image.gatsbyImageData)}
                    title={image.title}
                    file={image.file}
                    showTitle={true}
                    onClick={(e) => {
                      if (image.file.contentType.includes('video')) {
                        return;
                      }
                      setCurrentIndex(index);
                      setOpen(true);
                    }}
                  >
                  </ImageCard>
                )}
              </Box>
             })}
          </Masonry>
          <CustomLightBox
            images={contentfulIllustrationsSingleImages.images.flatMap(img => {
              return img.file.contentType.includes('video') ? [false] : [{ src: img.url, alt: img.alt }]
            })}
            currentImageIndex={currentImageIndex}
            setCurrentIndex={setCurrentIndex}
            isOpen={isOpen}
            onClose={() => {setOpen(false)}}
          ></CustomLightBox>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulIllustrationsPage {
      id
      title
      description
    }
    allContentfulIllustrationsSeries(sort: {fields: year, order: DESC}) {
    nodes {
      id
      title {
        title
      }
      client
      cover {
        title
        alt
        file
        gatsbyImageData
        id
        url
      }
      images {
        id
        alt
        gatsbyImageData
        url
        file
      }
      url
    }
  }
  contentfulIllustrationsSingleImages {
    images {
      id
      alt
      description
      gatsbyImageData
      file
      title
      url
    }
  }
  }
`
